@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

/*
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*/

/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        
    }

    body {
        @apply text-gray-800;

        font-family: 'Lato', sans-serif;

        /*
        font-family: 'Montserrat', sans-serif;
        font-family: 'Poppins', sans-serif;
        */

        /*
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        */

        background: #FFFFFF;
    }

    body.dark {
        @apply bg-gray-800;
    }
}

@layer utilities {
    
}

@layer components {
    .form-control {
        @apply focus:ring-gray-500 focus:border-gray-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md;
    }

    .transparent-form-control {
        @apply bg-transparent w-full border-0 focus:ring-0 p-0 placeholder-gray-300;
    }
}

.login-bg {
    background-image: url('../img/login-background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.quill {
    
}

.quill .ql-toolbar {
    @apply border-0 bg-white rounded-t-md;
}

.quill .ql-toolbar button:hover .ql-stroke,
.quill .ql-toolbar button.ql-active .ql-stroke
{
    @apply stroke-primary;
}

.quill .ql-toolbar button:hover .ql-fill,
.quill .ql-toolbar button.ql-active .ql-fill
{
    @apply fill-primary;
}

.quill .ql-container {
    @apply border-0;
}

.quill .ql-container .ql-editor {
    @apply text-base;
}

.messages {

}

.messages ol {
    padding-left: 20px;
    list-style: decimal;
}

.messages ul {
    padding-left: 20px;
    list-style: disc;
}

.messages a {
    text-decoration: underline;
    @apply text-primary;
}

.messages blockquote {
    @apply py-1.5 px-2 border-l-4 border-gray-400;
}